export default [
  {
    path: '/config/goga-earn',
    component: () => import('./views/Index'),
    name: '',
    redirect: { name: 'config-goga-earn' },
    children: [
      {
        path: '/',
        name: 'config-goga-earn',
        component: () => import('./views/List'),
        meta: {
          resource: 'goga-earn',
        },
      },
    ],
  },
]
